@main-color: #1890ff;
@main-text: black;
@main-hover: rgb(255, 255, 255);
@sub-color: #051d33;
@sub-text: white;
@sub-hover: rgb(255, 255, 255);
@transparent-0: rgba(0, 0, 0, 0);

.app-home-list {
  width: 100%;
}

.text-center {
  text-align: center;
}

h1 {
  font-size: 2em;
  line-height: 2em;

  &:first-child {
    margin-top: 0;
  }
}

h2 {
  font-size: 1.5em;
  line-height: 1.5em;

  &:first-child {
    margin-top: 0;
  }
}

p {
  line-height: 1.5em;
}

a {
  color: @main-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.skill0 {
  color: #0b0;
}
.skill1 {
  color: #0ba;
}
.skill2 {
  color: #00b;
}
.skill3 {
  color: #b0b;
}
.skill4 {
  color: #b00;
}
.skill5 {
  color: #c80;
}
.skill6 {
  color: #0b0;
  text-shadow: 1px 1px 2px #0b0;
}
.skill7 {
  color: #0ba;
  text-shadow: 1px 1px 2px #0ba;
}
.skill8 {
  color: #00b;
  text-shadow: 1px 1px 2px #00b;
}
.skill9 {
  color: #a0b;
  text-shadow: 1px 1px 2px #a0b;
}
.skill10 {
  color: #b00;
  text-shadow: 1px 1px 2px #b00;
}
.skill11 {
  color: #c80;
  text-shadow: 1px 1px 2px #c80;
}
.skill12 {
  color: #000;
  text-shadow: 1px 1px 2px #ff0;
}
.skill13 {
  color: #0f0;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000, 2px 2px 1px #888;
}
.skill14 {
  color: #0ff;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000, 2px 2px 1px #888;
}
.skill15 {
  color: #66f;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000, 2px 2px 1px #888;
}
.skill16 {
  color: #f0f;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000, 2px 2px 1px #888;
}
.skill17 {
  color: #f66;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000, 2px 2px 1px #888;
}
.skill18 {
  color: #fd2;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000, 2px 2px 1px #888;
}
.skill19 {
  color: #8ff;
  font-weight: 700;
  text-shadow: -1px 0 #080, 0 1px #080, 1px 0 #080, 0 -1px #080, 0 0 10px #0cc;
}
.skill20 {
  color: #fff;
  font-weight: 700;
  text-shadow: -1px 0 #00f, 0 1px #00f, 1px 0 #00f, 0 -1px #00f, 0 0 10px #00f;
}
.skill21 {
  color: #fff;
  font-weight: 700;
  text-shadow: -1px 0 #f0f, 0 1px #f0f, 1px 0 #f0f, 0 -1px #f0f, 0 0 10px #f0f;
}
.skill22 {
  color: #fff;
  font-weight: 700;
  text-shadow: -1px 0 #f44, 0 1px #f44, 1px 0 #f44, 0 -1px #f44, 0 0 10px #f44;
}
.skill23 {
  color: gold;
  font-weight: 700;
  text-shadow: -1px 0 #630, 0 1px #630, 1px 0 gold, 0 -1px #630, 0 0 10px gold;
}
.skill24 {
  color: #808080;
  font-weight: 700;
  text-shadow: -1px 0 #333, 0 1px #333, 1px 0 #333, 0 -1px #333, 0 0 10px #333;
}
.skill25 {
  background: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 3s ease-in-out infinite;
  background-size: 800% 100%;
  font-weight: 700;
  text-shadow: -1px 0 rgba(255, 255, 255, 0.2), 0 1px rgba(255, 255, 255, 0.2), 1px 0 rgba(255, 255, 255, 0.2), 0 -1px rgba(255, 255, 255, 0.2), 0 0 10px rgba(255, 255, 255, 0.2);
}

@keyframes rainbow_animation {
  0%,100% {
      background-position: 0 0;
  }

  50% {
      background-position: 50% 0;
  }
}
.result-new {
  background-color: #fce205;
}

.result-accept {
  background-color: #52c41a;
}

.result-reject {
  background-color: #cd5c5c;
}

.result-canceled {
  background-color: #b2b2b2;
}
